'use client'; // Error components must be Client Components
import { useEffect } from 'react';
import { Button } from 'ui';
import { PiramidLogo } from 'shared-components';
import { serializeError } from 'serialize-error';
import wretch from 'wretch';
import Link from 'next/link';

export default function Error({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  useEffect(() => {
    error.message = `${error.message} - DIGEST: ${error?.digest || 'NONE'}`;

    wretch().post(
      {
        error: serializeError(error),
      },
      '/api/register/error',
    );
  }, [error]);

  return (
    <div className='h-screen w-full flex items-center justify-center'>
      <div className='p-3 max-w-lg text-center w-full flex items-center justify-center flex-col space-y-4'>
        <Link href={'/'}>
          <PiramidLogo size={'2xl'} variant='black' />
        </Link>
        <h2 className='text-3xl font-bold'>Algo salió mal</h2>
        <p>
          El equipo de Piramid ya fue notificado del error y lo solucionará lo
          antes posible. Disculpa por las molestias ocasionadas.
        </p>
        <Button onClick={() => reset()}>Reintentar</Button>
      </div>
    </div>
  );
}
